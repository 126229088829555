import { Box, Image } from "@chakra-ui/core";
import React from "react";
import { ReduxProps, connector } from "../components/home/HomeConstants";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import Card from "../components/Card";
import { configFile } from "../configFile";

const PromotionScreen = (reduxProps: ReduxProps): JSX.Element => {
  React.useEffect(() => {
    if (!reduxProps.homeBackgrounds[0]) {
      reduxProps.NewsActions.fetchHomeBackgrounds();
    }
    return () => {};
  }, [reduxProps.homeBackgrounds, reduxProps.NewsActions]);

  const promotionWeb = reduxProps.homeBackgrounds.find((e) =>
    e.key?.toLowerCase().includes("promotionweb")
  )?.picture?.filePath;

  const promotionMWeb = reduxProps.homeBackgrounds.find((e) =>
    e.key?.toLowerCase().includes("promotionmweb")
  )?.picture?.filePath;

  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile: boolean = width <= 600;

  console.log("promotionWeb", promotionWeb);

  return (
    <>
      <Navbar />
      <Box as="main" color="black" mt={8} w="100%">
        <Card shadow="2px 2px 2px 0px rgba(0,0,0,0.75)">
          <Image
            src={`${configFile.serverBaseUrlPicture}${
              isMobile ? promotionWeb : promotionMWeb
            }`}
          />
        </Card>
      </Box>
      <Footer />
    </>
  );
};

export default connector(PromotionScreen);

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  generatePath,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom';

import Card from '../components/Card';
import Item, { useModifyProductStore } from '../components/item/Item';
import ItemAddedToCartToast from '../components/item/ItemAddedToCartToast';
import { CATEGORY_CITY_ID, CITY_ID } from '../config/categories';
import CartActions from '../redux/cart/CartActions';
import OrderActions from '../redux/orders/OrderActions';
import ProductActions from '../redux/products/ProductActions';
import { ICategory, IProduct } from '../redux/products/ProductTypes';
import { RootState } from '../redux/store';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

const SHOW_TAB_CATEGORIES = [
  'gyros',
  'freshlybaked',
  'salad',
  'pasta',
  'menu',
  'hamburger',
];

type ExtraPriceType = {
  lactoseFree28: number;
  lactoseFree52: number;
  fitness: number;
};

function ItemViewScreen(reduxProps: ReduxProps): JSX.Element {
  const [extraPrices, setExtraPrices] = useState<ExtraPriceType>();
  const { categories } = reduxProps;
  const history = useNavigate();
  const { city: cityParam, category: categoryParam } = useParams<{
    city: string;
    category: string;
  }>();
  const location = useLocation();
  const city = location.pathname.split('/')[1];
  const catName = location.pathname.split('/')[2];

  const cityId = CITY_ID.find((e) => e.name === cityParam);
  const selectedCategory = CATEGORY_CITY_ID.find(
    (e) => e.name === categoryParam && e.city === cityId
  );
  const mainCat = categories.find((e) => e.id === cityId?.id);
  const currentCat =
    catName === 'premium'
      ? mainCat?.childCategories?.find((e) => e.name === 'Prémium')
      : catName === 'kings-selection'
      ? mainCat?.childCategories?.find((e) => e.name === 'Királyi')
      : mainCat?.childCategories?.find((e) => e.id === selectedCategory?.id) ||
        null;

  const voucher1 = mainCat?.items[0] || [];
  const voucher2 = mainCat?.items[1] || [];
  const voucher3 = mainCat?.items[2] || [];

  const [productsInCategory, setProductsInCategory] = useState<IProduct[]>(
    currentCat?.items ?? []
  );
  const [drinkTabId, setDrinkTabId] = useState<number | undefined>(); // childcategory (sör, bor etc) id

  const handleTabClick = (catId: number) => {
    setDrinkTabId(catId);
    setProductsInCategory(
      currentCat?.childCategories.find((c) => c.id === catId)?.items ?? []
    );
  };

  let itemsToShow = [1, 2, 3].map((id) => (
    <Card key={id} minW='280px'>
      <Skeleton h='24px' mb={4} />
      <Skeleton h='16px' mb={6} />
      <Skeleton h='250px' mb={4} />
      <Flex justify='flex-end' w='100%'>
        <Skeleton h='32px' width='100px' />
      </Flex>
    </Card>
  ));

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modifyProduct, setModifyProduct] = useModifyProductStore();

  const compareNames = (
    first: ICategory | IProduct,
    second: ICategory | IProduct
  ) => first.name.localeCompare(second.name);

  useEffect(
    () => {
      if (reduxProps.categories.length < 1) {
        reduxProps.ProductActions.fetchAllCategory();
        reduxProps.ProductActions.fetchAllProduct();
      }
      const dtid =
        currentCat?.childCategories
          .sort(compareNames)
          .find((cc) => !cc.isHiddenInFrontend)?.id ?? 0;
      setDrinkTabId(dtid);

      setProductsInCategory(
        dtid && dtid !== 0 && SHOW_TAB_CATEGORIES.includes(categoryParam)
          ? currentCat?.childCategories.find((c) => c.id === dtid)?.items ?? []
          : currentCat?.items ?? []
      );
    },
    // eslint-disable-next-line
    [
      cityId,
      selectedCategory,
      reduxProps.ProductActions,
      currentCat,
      reduxProps.categories.length,
    ]
  );

  useEffect(() => {
    reduxProps.OrderActions.fetchPromotionPrices()
      .then((resp) => {
        setExtraPrices({
          lactoseFree28: resp.data.lactoseFree28,
          lactoseFree52: resp.data.lactoseFree52,
          fitness: resp.data.fitness,
        });
      })
      .catch((error: Error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    currentCat ||
    catName === 'premium' ||
    location.pathname.split('/')[2] === 'kings-selection'
  ) {
    if (catName === 'premium') {
      itemsToShow = mainCat?.childCategories
        ?.filter((e) => e.name === 'Prémium')[0]
        .childCategories?.filter((e) => !e.isHiddenInFrontend)
        .sort(compareNames)
        .map((product) => (
          <Item
            reduxProps={reduxProps}
            products={reduxProps.products}
            key={product.id}
            category={product}
            isPizza
            modalOpener={onOpen}
          />
        ));
    }
    if (catName === 'kings-selection') {
      itemsToShow = mainCat?.childCategories
        ?.filter((e) => e.name === 'Királyi')[0]
        .childCategories?.filter((e) => !e.isHiddenInFrontend)
        .sort(compareNames)
        .map((product) => (
          <Item
            reduxProps={reduxProps}
            products={reduxProps.products}
            key={product.id}
            category={product}
            isPizza
            modalOpener={onOpen}
          />
        ));
    } else if (
      currentCat.name.toLowerCase() === 'pizza' &&
      currentCat.childCategories
    ) {
      itemsToShow = currentCat?.childCategories
        ?.filter((e) => !e.isHiddenInFrontend)
        .sort(compareNames)
        .map((product) => (
          <Item
            reduxProps={reduxProps}
            key={product.id}
            products={reduxProps.products}
            category={product}
            isPizza
            modalOpener={onOpen}
            extraPrices={extraPrices}
          />
        ));
    } else if (
      SHOW_TAB_CATEGORIES.includes(categoryParam.toLowerCase()) &&
      currentCat.name.toLowerCase() === 'menü'
    ) {
      itemsToShow =
        productsInCategory
          .concat(voucher1, voucher2, voucher3)
          .filter((e) => e.isAvailable)
          .sort(compareNames)
          .map((prod) => (
            <Item
              reduxProps={reduxProps}
              key={prod.id}
              product={prod}
              modalOpener={onOpen}
              isMenu
            />
          )) ?? [];
    } else if (
      SHOW_TAB_CATEGORIES.includes(categoryParam.toLowerCase()) &&
      currentCat.name.toLowerCase() !== 'menü'
    ) {
      itemsToShow =
        productsInCategory
          .concat(voucher1, voucher2, voucher3)
          .filter((e) => e.isAvailable)
          .sort(compareNames)
          .map((prod) => (
            <Item
              reduxProps={reduxProps}
              key={prod.id}
              product={prod}
              modalOpener={onOpen}
            />
          )) ?? [];
    } else if (categoryParam.toLowerCase() === 'drink') {
      itemsToShow =
        currentCat?.childCategories
          ?.find((cc) => cc.id === drinkTabId)
          ?.childCategories.sort(compareNames)
          .filter((c) => !c.isHiddenInFrontend)
          .map((category) => (
            <Item
              reduxProps={reduxProps}
              key={category.id}
              category={category}
              isDrink
              modalOpener={onOpen}
            />
          )) ?? [];
    } else if (productsInCategory.length > 0) {
      itemsToShow = currentCat.items
        .concat(voucher1, voucher2, voucher3)
        .sort(compareNames)
        .filter((e) => e.isAvailable)
        .map((product) => (
          <Item
            reduxProps={reduxProps}
            key={product.id}
            product={product}
            modalOpener={onOpen}
          />
        ));
    }
  }

  const MultipleTabs = () => {
  
    return (
      <Flex ml={[2, 8]}>
        {currentCat?.childCategories
          .filter((c) => !c.isHiddenInFrontend)
          .sort(compareNames)
          .map((childCat) => (
            <Box
              key={childCat.id}
              p={1}
              mr={2}
              w='200px'
              borderRadius='8px 8px 0 0'
              textAlign='center'
              color={childCat.name === 'Gluténmentes' ? 'white' : 'black'}
              backgroundColor={
                childCat.name === 'Gluténmentes'
                  ? '#68B3EC'
                  : childCat.id !== drinkTabId
                  ? 'rgba(255,255,255,0.7)'
                  : 'white'
              }
              textTransform='uppercase'
              fontWeight='bold'
              cursor='pointer'
              onClick={() => handleTabClick(childCat.id)}
              fontSize='15px'
            >
              {childCat?.name}
            </Box>
          ))}
      </Flex>
    );
  };

  const DefaultTab = () =>
    currentCat?.name === 'Pizza' ||
    location.pathname.split('/')[2] === 'premium' ||
    location.pathname.split('/')[2] === 'kings-selection' ? (
      <Flex ml={[2, 8]}>
        <Box
          key={1}
          p={1}
          mr={2}
          w='200px'
          borderRadius='8px 8px 0 0'
          textAlign='center'
          backgroundColor={'white'}
          textTransform='uppercase'
          fontWeight='bold'
          cursor='pointer'
          onClick={() => history(`/${city}/pizza`)}
          fontSize='15px'
        >
          Pizzák
        </Box>
        <Box
          key={2}
          p={1}
          mr={2}
          w='200px'
          borderRadius='8px 8px 0 0'
          textAlign='center'
          backgroundColor={'rgb(218,21,21)'}
          textTransform='uppercase'
          fontWeight='bold'
          cursor='pointer'
          color='white'
          onClick={() => history(`/${city}/premium`)}
          fontSize='15px'
        >
          Prémium Pizzák
        </Box>
        {location.pathname.split('/')[1] === 'kecskemet' ? (
          <Box
            key={2}
            p={1}
            mr={2}
            w='200px'
            borderRadius='8px 8px 0 0'
            textAlign='center'
            backgroundColor={'rgb(218,165,32)'}
            textTransform='uppercase'
            fontWeight='bold'
            cursor='pointer'
            color='white'
            onClick={() => history(`/${city}/kings-selection`)}
            fontSize='15px'
          >
            Királyi válogatás
          </Box>
        ) : null}
      </Flex>
    ) : (
      <Card
        p={1}
        ml={8}
        w='200px'
        borderRadius='8px 8px 0 0'
        textAlign='center'
        textTransform='uppercase'
        fontWeight='bold'
      >
        {currentCat?.name}
      </Card>
    );

  const Tabs = () =>
    [...SHOW_TAB_CATEGORIES, 'drink'].includes(categoryParam) ? (
      <MultipleTabs />
    ) : (
      <DefaultTab />
    );
  const router = useNavigate();
  if (cityParam !== 'kecskemet' && cityParam !== 'szolnok') {
    router('/', { replace: true });
    return <></>;
  }
  return (
    <>
      <Navbar />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius='8px'>
          <ModalHeader>Adj extra feltétet a termékedhez</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={2}>
              <Heading size='md'>{modifyProduct.name}</Heading>

              {modifyProduct.contents.map((e) => {
                let additionalCatHiearchy;

                if (currentCat?.name.toLowerCase() !== 'ital') {
                  additionalCatHiearchy = currentCat?.childCategories?.find(
                    (c) => c.id === e.categoryId
                  );
                } else {
                  additionalCatHiearchy = e.category;
                }

                const ad = additionalCatHiearchy?.items.map((item) => (
                  <HooksCheckbox
                    key={JSON.stringify(item)}
                    item={item}
                    isFreeContent
                    setModifyProduct={setModifyProduct}
                    modifyProduct={modifyProduct}
                    allowedCount={1}
                  />
                ));
                if (additionalCatHiearchy?.name) {
                  return (
                    <React.Fragment key={e.categoryId}>
                      {' '}
                      <Text style={{ fontWeight: 'bold' }} pb={2}>
                        {additionalCatHiearchy?.name} - Válassz egyet!*
                      </Text>
                      {ad}
                    </React.Fragment>
                  );
                }
                return '';
              })}

              {modifyProduct.additionalItems.map((e) => {
                let additionalCatHiearchy;
                if (currentCat?.name.toLowerCase() !== 'ital') {
                  additionalCatHiearchy = currentCat?.childCategories?.find(
                    (c) => c.id === e.categoryId
                  );
                } else {
                  additionalCatHiearchy = e.category;
                }
                const ad = additionalCatHiearchy?.items.map((item) => {
                  return (
                    <HooksCheckbox
                      key={JSON.stringify(item)}
                      item={item}
                      setModifyProduct={setModifyProduct}
                      modifyProduct={modifyProduct}
                      allowedCount={e.maxQuantity}
                    />
                  );
                });

                return (
                  <React.Fragment key={e.categoryId}>
                    {' '}
                    <Text style={{ fontWeight: 'bold' }} pb={2}>
                      {additionalCatHiearchy?.name} - Maximum: {e.maxQuantity}{' '}
                      db
                    </Text>
                    {ad}
                  </React.Fragment>
                );
              })}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              variantColor='green'
              mr={3}
              onClick={() => {
                let everythingAdded = true;
                modifyProduct.contents.forEach((content) => {
                  if (content.categoryId) {
                    const currentCategory = content.categoryId;
                    let currentCatAdded = false;

                    modifyProduct.contents.forEach((product) => {
                      if (product.item) {
                        if (product.item.parentCategoryId === currentCategory) {
                          currentCatAdded = true;
                        }
                      }
                    });

                    if (!currentCatAdded) {
                      everythingAdded = false;
                    }
                  }
                });

                if (everythingAdded) {
                  reduxProps.CartActions.addToCart(modifyProduct, 0);
                  toast({
                    duration: 3000,
                    position: 'top',
                    render: (onCloseToast) => (
                      <ItemAddedToCartToast
                        onCloseToast={onCloseToast}
                        history={history}
                        href={generatePath('/:city/cart', {
                          city: cityParam,
                        })}
                      />
                    ),
                  });
                  onClose();
                } else {
                  toast({
                    title: 'Hiba',
                    description:
                      'Kérjük válasszon ki a csillaggal megjelölt kategóriákból minimum egyet!',
                    status: 'error',
                    position: 'top',
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }}
            >
              Termék hozzáadása a kosárhoz
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box as='main' color='black' mt={8} w='100%'>
        <Tabs />
        <Card shadow='2px 2px 2px 0px rgba(0,0,0,0.75)'>
          {currentCat?.name === 'Pizza'
            ? 'Minden pizzánk paradicsomos alappal készül,ha változtatni akarsz tervezd át! Próbáld ki teljes kiőrlésű FITT pizzatésztáinkat is!'
            : location.pathname.split('/')[2] === 'premium'
            ? 'Próbáld ki prémium pizzáinkat!'
            : location.pathname.split('/')[2] === 'kings-selection'
            ? 'Ismerd meg királyi családunkat és udvartartásukat!'
            : currentCat?.comment}
        </Card>
        <Grid
          w='100%'
          my={2}
          gridColumnGap={2}
          gridRowGap={2}
          gridTemplateColumns={[
            'repeat(auto-fill, 100%)',
            'repeat(auto-fill, 100%)',
            '1fr 1fr',
            '1fr 1fr 1fr',
          ]}
          justifyContent='center'
        >
          {itemsToShow}
        </Grid>
      </Box>
      <Footer />
    </>
  );
}

function HooksCheckbox({
  modifyProduct,
  setModifyProduct,
  item,
  allowedCount,
  isFreeContent,
}: {
  item: IProduct;
  setModifyProduct: (newStoreState: IProduct) => void;
  modifyProduct: IProduct;
  allowedCount: number;
  isFreeContent?: boolean;
}) {
  const [isChecked, toggleChecked] = React.useState(false);
  let isCheckedB = false;
  return (
    <Checkbox
      key={item.id}
      value={item.id}
      isChecked={isChecked}
      onChange={() => {
        let currentCount = 0;
        modifyProduct.contents.forEach((content) => {
          if (
            content.item?.parentCategoryId === item.parentCategoryId &&
            item !== content.item
          )
            currentCount++;
        });

        if (currentCount >= allowedCount) {
          return;
        }
        isCheckedB = isChecked;
        toggleChecked(!isChecked);
        isCheckedB = !isCheckedB;

        if (isCheckedB) {
          setModifyProduct({
            ...modifyProduct,
            contents: [...modifyProduct.contents, { item, quantity: 1 }],
          });
        } else {
          const tempContents = modifyProduct.contents.filter(
            (e) => e.item !== item
          );
          setModifyProduct({
            ...modifyProduct,
            contents: tempContents,
          });
        }
      }}
    >
      {item.name} {item.price !== 0 && !isFreeContent && `(${item.price} Ft)`}
    </Checkbox>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    products: state.productReducer.products,
    categories: state.productReducer.categories,
    cartItems: state.cartReducer.cartItems,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ProductActions: ProductActions(dispatch),
    CartActions: CartActions(dispatch),
    OrderActions: OrderActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(ItemViewScreen);

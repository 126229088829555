import { Box } from "@chakra-ui/core";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import AboutUsScreen from "./screens/AboutUsScreen";
import CartScreen from "./screens/CartScreen";
import ChooseCityScreen from "./screens/ChooseCityScreen";
import ConfirmationScreen from "./screens/ConfirmationScreen";
import CreatorScreen from "./screens/CreatorScreen";
import HomeScreen from "./screens/HomeScreen";
import ItemViewScreen from "./screens/ItemViewScreen";
import NewsScreen from "./screens/NewsScreen";
import CouponScreen from "./screens/CouponScreen";
import DeliveryFeeScreen from "./screens/DeliveryFeeScreen";
import PrivacyScreen from "./screens/PrivacyScreen";
import OpenHoursScreen from "./screens/OpenHoursScreen";

import { useDispatch } from "react-redux";
import PromotionScreen from "./screens/PromotionScreen";

const RouterApp = (): JSX.Element => {
  const history = useLocation();
  const dispatch = useDispatch();

  if (history.pathname === "/") {
    dispatch({ type: "EMPTY_CART", product: undefined });
  }
  return (
    <>
      <Box
        maxW={["100%", "540px", "720px", "960px", "1140px"]}
        max-w="100vw"
        w="100%"
        flexShrink={0}
        flex="1 0 auto"
        p={0}
        m={0}
      >
        <Routes>
          <Route path="/" element={<ChooseCityScreen />} />

          <Route
            path="/:city/kiszallitasidij"
            element={<DeliveryFeeScreen />}
          />
          <Route path="/:city/privacy" element={<PrivacyScreen />} />
          <Route path="/:city/nyitvatartas" element={<OpenHoursScreen />} />
          <Route path="/:city" element={<HomeScreen />} />
          <Route path={"/:city/hirek"} element={<NewsScreen />} />
          <Route path={"/:city/news"} element={<NewsScreen />} />
          <Route path={"/:city/aktualis"} element={<NewsScreen />} />
          <Route
            path={"/:city/kreator/:creatorName"}
            element={<CreatorScreen />}
          />
          <Route
            path={"/:city/creator/:creatorName"}
            element={<CreatorScreen />}
          />
          <Route path={"/:city/kosar"} element={<CartScreen />} />
          <Route path={"/:city/cart"} element={<CartScreen />} />
          <Route path={"/:city/success"} element={<ConfirmationScreen />} />
          <Route path={"/:city/cancelled"} element={<ConfirmationScreen />} />
          <Route path={"/:city/declined"} element={<ConfirmationScreen />} />
          <Route path="/:city/about" element={<AboutUsScreen />} />
          {/* {reduxProps.userIsLoggedIn && ( */}
          <Route path="/:city/coupons" element={<CouponScreen />} />
          <Route path="/:city/promotion" element={<PromotionScreen />} />
          {/*  />} */}

          <Route path="/:city/:category" element={<ItemViewScreen />} />
        </Routes>
      </Box>
    </>
  );
};

export default RouterApp;
